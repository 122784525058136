import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { BlockPageStyled } from './styled';
import Layout from '@/components/layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATH } from '@/constants';
import { Badge, Button, Card, InlineGrid, LegacyStack, Link, List, RadioButton, Text, TextField } from '@shopify/polaris';
import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { ActionType, BREAKPOINT, CriteriaType, ListType } from '@/constants/enum';
import { useMediaQuery } from 'react-responsive';
import useCondition from '../../hooks/useCondition';
import { useDispatch, useSelector } from 'react-redux';
import blockListSlice, { settingBackupSelector, settingSelector } from '@/redux/slice/blockList.slice';
import useCountry from '@/hooks/useCountry';
import { formatDate, removeFalsyValues, validateUrl, checkShowErrorInline, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import toastSlice from '@/redux/slice/toast.slice';
import ModalConfirm from '@/components/ModalConfirm';
import useErrorRule from '../../hooks/useErrorRule';
import ButtonSupport from '@/components/ButtonSupport';
import UserPlans from '@/hooks/userPlans';
import HelpCenter from '@/components/HelpCenter';
import CustomBadge from '@/components/CustomBadge';

const BlockPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const useError = useErrorRule();
  const handleCountry = useCountry();
  const conditionHook = useCondition();
  const { state } = useLocation();
  const { userPlanFree, userPlanPremium, shopifyPlanPlus } = UserPlans();
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });

  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [linkRedirectError, setLinkRedirectError] = useState('');

  const blockRuleSelected = useSelector(settingSelector);
  const blockRuleBackupSelected = useSelector(settingBackupSelector);

  const [upsertRule, upsertRuleStatus] = apiCaller.useUpsertRuleMutation();
  const [deleteItem, deleteItemStatus] = apiCaller.useDeleteSettingMutation();

  const condition = conditionHook.condition.find((item) =>
    item.groupCriteria.includes(blockRuleSelected.criteria as CriteriaType),
  );
  const listCriteria = conditionHook.listCriteria.filter((item) => condition?.groupCriteria.includes(item.value));
  const criteriaSelected = listCriteria.find((item) => item.value === blockRuleSelected.criteria);

  useEffect(() => {
    if (!criteriaSelected) {
      navigate(PATH.BLOCKLIST);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!criteriaSelected]);

  const handleChange = (key: keyof typeof blockRuleSelected) => (value: string | boolean | number) => {
    dispatch(
      blockListSlice.actions.handleSetting({
        ...blockRuleSelected,
        [key]: value,
      }),
    );
  };

  const handleDisabled = useMemo(() => {
    const fieldsRequired = condition?.fieldsRequired;
    if (fieldsRequired) {
      const disabled = !!fieldsRequired.find((field) => {
        const value = blockRuleSelected[field as keyof typeof blockRuleSelected];
        if (blockRuleSelected.type === ActionType.Redirect) {
          return (
            (Array.isArray(value) && value.length === 0) ||
            !value ||
            !blockRuleSelected.linkRedirect ||
            (blockRuleSelected.linkRedirect && !validateUrl(blockRuleSelected.linkRedirect)) ||
            useError.listError.length > 0
          );
        }
        return (Array.isArray(value) && value.length === 0) || !value || useError.listError.length > 0;
      });
      return (
        disabled || (!!blockRuleSelected.id && JSON.stringify(blockRuleBackupSelected) === JSON.stringify(blockRuleSelected))
      );
    }
    return false;
  }, [blockRuleBackupSelected, blockRuleSelected, condition?.fieldsRequired, useError.listError.length]);

  const handleSave = async () => {
    try {
      const { isActive, ...data } = blockRuleSelected;
      const dataRemovedFalsyValues = removeFalsyValues(data);
      upsertRule({
        ...dataRemovedFalsyValues,
        type: blockRuleSelected.type,
        priority: blockRuleSelected.priority,
        criteria: blockRuleSelected.criteria as CriteriaType,
        isActive,
        overrideVpn: blockRuleSelected.priority === ListType.WhiteList ? blockRuleSelected.overrideVpn || 0 : undefined,
        createdAt: undefined,
        ipRangeType: blockRuleSelected.ipRangeType,
      }).then((res) => {
        if ('data' in res) {
          if (res.data.state === 1) {
            navigate(PATH.BLOCKLIST);
            dispatch(blockListSlice.actions.clearSetting());
          } else {
            let error: string | React.ReactElement | undefined;
            if (res.data?.url) {
              error = (
                <Text as="span" variant="bodyMd" tone="critical">
                  Unable to add some new entries due to invalid format or existing values: See details{' '}
                  <Link url={res.data?.url}>here</Link>
                </Text>
              );
            } else {
              error = (
                <div>
                  {res.data?.failureRules?.map((item) => (
                    <p key={item.errValue}>
                      <b>{item.errValue}: </b>
                      {item.errMsg}. Try to add another {criteriaSelected?.fieldName}.
                    </p>
                  ))}
                </div>
              );
            }
            useError.handleErrorRule(error);
          }
        }
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = useCallback(() => {
    if (blockRuleSelected.id) {
      deleteItem({ id: blockRuleSelected.id }).then((res) => {
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
          navigate(PATH.BLOCKLIST);
        }
      });
    }
  }, [blockRuleSelected.id, deleteItem, dispatch, navigate]);

  const ruleSummary: Array<{
    title: string;
    value?: string;
    regular?: boolean;
    visible?: CriteriaType[];
  }> = useMemo(() => {
    // [title , value , regular,condition visible]
    return [
      {
        title: 'Status',
        value: blockRuleSelected.isActive ? 'Enable' : 'Disable',
        regular: true,
      },
      {
        title: 'Condition',
        value: criteriaSelected?.label,
      },
      {
        title: 'IP address blocking',
        value: blockRuleSelected.ipAddress,
        regular: false,
        visible: [CriteriaType.IpAddress, CriteriaType.IpAddressStartWith, CriteriaType.IpRanges],
      },
      {
        title: 'Product name',
        value: blockRuleSelected.productName?.join(', '),
        regular: false,
        visible: [CriteriaType.Product],
      },
      {
        title: 'Collection name',
        value: blockRuleSelected.collectionName?.join(', '),
        regular: false,
        visible: [CriteriaType.Collection],
      },

      {
        title: 'Page',
        value: blockRuleSelected.pageTitle?.join(', '),
        regular: false,
        visible: [CriteriaType.SpecificPage],
      },

      {
        title: 'Country',
        value: blockRuleSelected.country?.map((item) => handleCountry.renderCountry(item)).join(', '),
        regular: false,
        visible: [
          CriteriaType.Collection,
          CriteriaType.Product,
          CriteriaType.Country,
          CriteriaType.Province,
          CriteriaType.ISP,
          CriteriaType.ReferralLink,
          CriteriaType.ReferralLink,
          CriteriaType.SpecificPage,
          CriteriaType.UserAgent,
        ],
      },

      {
        title: 'Province selected',
        value: blockRuleSelected.state?.join(', '),
        regular: false,
        visible: [CriteriaType.Province],
      },

      {
        title: 'City selected',
        value: blockRuleSelected.city?.join(', '),
        regular: false,
        visible: [CriteriaType.Province],
      },

      {
        title: 'Browser',
        value: blockRuleSelected.browserName?.join(', '),
        regular: false,
        visible: [CriteriaType.UserAgent],
      },
      {
        title: 'Device',
        value: blockRuleSelected.deviceType,
        regular: false,
        visible: [CriteriaType.UserAgent],
      },
      {
        title: 'OS',
        value: blockRuleSelected.osName,
        regular: false,
        visible: [CriteriaType.UserAgent],
      },

      {
        title: 'ISP',
        value: blockRuleSelected.ispName?.join(', '),
        regular: false,
        visible: [CriteriaType.ISP],
      },

      {
        title: 'Referral URL',
        value: blockRuleSelected.referUrl,
        regular: false,
        visible: [CriteriaType.ReferralLink],
      },

      {
        title: 'Created at',
        value: blockRuleSelected.createdAt ? formatDate(blockRuleSelected.createdAt / 1000, 'D MMM YYYY, h:mm:ss a') : '',
        regular: true,
        visible: blockRuleSelected.id ? undefined : [],
      },

      {
        title: 'Last updated at',
        value: blockRuleSelected.lastUpdatedAt ? formatDate(blockRuleSelected.lastUpdatedAt, 'D MMM YYYY, h:mm:ss a') : '',
        regular: true,
        visible: blockRuleSelected.id ? undefined : [],
      },
    ];
  }, [blockRuleSelected, criteriaSelected, handleCountry]);

  const checkPaidPlanFromCriteria = (criteria: CriteriaType): boolean => {
    const freePlanFromCriteria = [
      CriteriaType.IpAddress,
      CriteriaType.IpAddressStartWith,
      CriteriaType.Country,
      CriteriaType.Province,
      CriteriaType.IpRanges,
    ];
    return !freePlanFromCriteria.includes(criteria);
  };
  const checkPremiumPlanFromCriteria = (criteria: CriteriaType): boolean => {
    const premiumPlanFromCriteria = [
      CriteriaType.IpAddress,
      CriteriaType.IpAddressStartWith,
      CriteriaType.Country,
      CriteriaType.Province,
      CriteriaType.IpRanges,
      CriteriaType.Product,
      CriteriaType.Collection,
      CriteriaType.UserAgent,
      CriteriaType.SpecificPage,
    ];
    return !premiumPlanFromCriteria.includes(criteria);
  };
  return (
    <BlockPageStyled>
      <Layout
        layoutProps={{
          title: `${blockRuleSelected.id ? 'Edit ' : ''}${
            blockRuleSelected.priority === ListType.WhiteList
              ? 'Whitelist'
              : blockRuleSelected.type === ActionType.Block
              ? 'Block'
              : 'Redirect'
          } ${condition?.label}`,
          backAction: {
            onAction: () => navigate(state?.prePath || PATH.BLOCKLIST),
          },
          titleMetadata:
            userPlanFree && checkPaidPlanFromCriteria(criteriaSelected?.value || CriteriaType.IpAddress) ? (
              <CustomBadge tone="info">Paid plan</CustomBadge>
            ) : userPlanPremium &&
              shopifyPlanPlus &&
              checkPremiumPlanFromCriteria(criteriaSelected?.value || CriteriaType.IpAddress) ? (
              <CustomBadge tone="info">Enterprise plan or Shopify plus</CustomBadge>
            ) : userPlanPremium &&
              !shopifyPlanPlus &&
              checkPremiumPlanFromCriteria(criteriaSelected?.value || CriteriaType.IpAddress) ? (
              <CustomBadge tone="info">Enterprise plan</CustomBadge>
            ) : null,
          primaryAction: (
            <div className="d-flex">
              <HelpCenter />
              <div className="ml-8">
                <ButtonSupport />
              </div>
            </div>
          ),
        }}
      >
        <InlineGrid columns={isMobile ? 1 : ['twoThirds', 'oneThird']} gap={'400'}>
          <div>
            <Card padding={'400'}>
              <div className="block-page-status">
                <BoldText>
                  Rule status{' '}
                  <Badge tone={blockRuleSelected.isActive ? 'success' : undefined}>
                    {!blockRuleSelected.isActive ? 'Disabled' : 'Enable'}
                  </Badge>
                </BoldText>
                <Button
                  onClick={() => handleChange('isActive')(!blockRuleSelected.isActive)}
                  variant={blockRuleSelected.isActive ? undefined : 'primary'}
                >
                  {blockRuleSelected.isActive ? 'Disabled' : 'Enable'}
                </Button>
              </div>
              <RegularText>Active rules are on and running. Inactive rules are off and not triggering actions.</RegularText>
            </Card>
            {blockRuleSelected.priority === ListType.WhiteList &&
              <div className='mt-16'>
                <Card padding={'400'}>
                  <div className="block-page-status">
                    <BoldText>
                      <div className='d-flex'>
                        Overwrite VPN status{' '}
                        <div className='ml-4 mr-4'>
                          <Badge tone={blockRuleSelected.overrideVpn === 1 ? 'success' : undefined}>
                            {blockRuleSelected.overrideVpn === 0 ? 'Disabled' : 'Enable'}
                          </Badge>
                        </div>
                        {userPlanFree && <CustomBadge tone='info'>Paid plan</CustomBadge>}
                      </div>
                    </BoldText>
                    <Button
                      onClick={() => handleChange('overrideVpn')(blockRuleSelected.overrideVpn === 0 ? 1 : 0)}
                      variant={blockRuleSelected.overrideVpn ? undefined : 'primary'}
                      disabled={userPlanFree}
                    >
                      {blockRuleSelected.overrideVpn === 1 ? 'Disabled' : 'Enable'}
                    </Button>
                  </div>
                </Card>
              </div>
            }
            <div className="mt-16 block-page-content">
              <Card padding={'400'}>
                {listCriteria.length > 1 ? (
                  <>
                    <BoldText>Select condition</BoldText>
                    <div className="mt-16">
                      <LegacyStack vertical>
                        {listCriteria.map((item) => {
                          return (
                            <RadioButton
                              disabled={!!blockRuleSelected.id}
                              key={item.value}
                              label={
                                item.badge ? (
                                  <div className="d-flex">
                                    {item.label}{' '}
                                    <div className="ml-4">
                                      <CustomBadge tone="info">{item.badge}</CustomBadge>
                                    </div>
                                  </div>
                                ) : (
                                  item.label
                                )
                              }
                              checked={item.value === blockRuleSelected.criteria}
                              id={`block-page-${item.value}`}
                              name={`block-page-${item.value}`}
                              onChange={() => {
                                dispatch(blockListSlice.actions.handleErrorRule([]));
                                dispatch(
                                  blockListSlice.actions.handleSetting({
                                    ...blockRuleSelected,
                                    criteria: item.value,
                                    referUrl: '',
                                    shortReferUrl: '',
                                    collectionId: [],
                                    state: [],
                                    city: [],
                                    country: [],
                                    ipAddress: '',
                                    ispName: [],
                                    ispCode: [],
                                    productId: [],
                                    pageId: [],
                                    deviceType: '',
                                    osName: '',
                                    browserName: [],
                                  }),
                                );
                              }}
                            />
                          );
                        })}
                      </LegacyStack>
                    </div>
                  </>
                ) : (
                  <BoldText>Condition: {criteriaSelected?.label}</BoldText>
                )}

                <div className="mt-8">
                  {blockRuleSelected.type === ActionType.Redirect ? (
                    <>
                      <TextField
                        label="Redirect to"
                        value={blockRuleSelected.linkRedirect}
                        onChange={(value) => {
                          handleChange('linkRedirect')(value);
                          if (linkRedirectError) {
                            setLinkRedirectError('');
                          }
                        }}
                        autoComplete="off"
                        prefix="https://"
                        requiredIndicator
                        error={linkRedirectError}
                        onBlur={() => {
                          if (!blockRuleSelected.linkRedirect) {
                            setLinkRedirectError('Redirect link must be added ');
                          } else if (!validateUrl(blockRuleSelected.linkRedirect))
                            setLinkRedirectError('Please enter a valid URL');
                        }}
                      />
                      <div className="mt-8" />
                      <TextField
                        label="Displayed name (optional)"
                        maxLength={32}
                        value={blockRuleSelected.shortUrl}
                        onChange={handleChange('shortUrl')}
                        autoComplete="off"
                        helpText="This name is displayed instead of the full URL to save space."
                      />
                    </>
                  ) : null}
                  {condition && condition.component
                    ? condition?.component({
                        label: `Enter ${criteriaSelected?.label}`,
                        placeholder: criteriaSelected?.placeholder,
                      })
                    : ''}
                </div>
              </Card>
            </div>
          </div>

          <div className="rule-summary-container">
            <Card padding={'0'}>
              <div className="block-page-rule-summary">
                <BoldText>Rule summary</BoldText>
              </div>
              <div className="block-page-rule-summary-content">
                <List type="bullet">
                  {ruleSummary
                    .filter((item) => {
                      if (!item.visible) return true;
                      return item.visible.includes(blockRuleSelected.criteria as CriteriaType);
                    })
                    .map((item) => {
                      return (
                        <>
                          <List.Item>
                            {item.title}:{' '}
                            {!item.value ? (
                              <Text as="span" variant="bodyMd" tone="subdued">
                                None
                              </Text>
                            ) : item.regular ? (
                              item.value
                            ) : (
                              <b>{item.value}</b>
                            )}
                          </List.Item>
                        </>
                      );
                    })}
                </List>
              </div>
            </Card>
          </div>
        </InlineGrid>
        <div className="block-page-group-button">
          {blockRuleSelected.id ? (
            <Button
              onClick={() => {
                setIsOpenModalDelete(true);
              }}
              tone="critical"
              variant="primary"
            >
              Delete
            </Button>
          ) : null}
          <Button disabled={handleDisabled} loading={upsertRuleStatus.isLoading} onClick={handleSave} variant="primary">
            Save
          </Button>
        </div>

        <ModalConfirm
          onClose={() => setIsOpenModalDelete(false)}
          isLoading={deleteItemStatus.isLoading}
          isOpen={isOpenModalDelete}
          title="Delete rule?"
          onConfirm={handleDelete}
          sectionText="If you delete the rule, you won't be able to revert it."
        />
      </Layout>
    </BlockPageStyled>
  );
};
export default memo(BlockPage);
